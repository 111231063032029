.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Rooms.css */

/* Styles for the dashboard component */
.dashboard-component {
  padding: 2%;
  background: linear-gradient(to bottom, #ffffff, #f4f6f8);
  border: 2px solid #ccc;
  border-radius: 5px;
  max-height: 100%;
  overflow-y: auto;
}

/* Styles for the quick summary section */
 .dashboard-component h2 {
  font-size: large;
  margin-bottom: 2%;
   
}

.rooms {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2, #ffebcd);
}

.rooms h4
{
  font-size: large;
  margin-bottom: 2%;
  text-align: center;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.rooms hr {
  margin-top: 2%;
  margin-bottom: 2%;
  border-width: 4px; /* Adjust the thickness as needed */

}

.rooms .quick-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  width: 100%;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2, #ffebcd);
}

.rooms .quick-summary p {
  font-size: medium;
  margin-right: 2%;
}

/* Styles for the Create Room button */
.rooms .create-room-button {
  font-size: medium;
}

/* Styles for the list of rooms */
.rooms .room-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 2%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2, #ffebcd);
}

.rooms .room-card .card-header {
  background-color: #369bc3;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize; /* Capitalize the room name */
  text-align: center; /* Center-align the room name */
}

.rooms .card-header,
.rooms h5 {
  background-color: #369bc3;
}

.rooms .room-card .card-body {
  padding: 10px;
}

/* Additional Meeting Details */
.rooms .room-card .card-body > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.rooms .room-card .card-body > div span {
  font-weight: bold;
}

/* Passcode */
.rooms .room-card .card-body .passcode {
  display: flex;
  align-items: center;
}

.rooms .room-card .card-body .passcode span {
  margin-right: 5px;
}

.rooms .room-card .card-body .passcode button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color:  #53c6e0; 
}

/* Action Buttons */
.rooms .room-card .card-body .action-buttons {
  display: flex;
  justify-content: center; /* Center-align the buttons */
  margin-top: 2%;
}

.rooms .room-card .card-body .action-buttons button {
  font-size: medium;
  margin: 0 2%; /* Add spacing between buttons */
}

/* Add this CSS to your existing CSS file */
.rooms .btn-copy-passcode {
  transition: transform 0.3s ease, opacity 0.3s ease; /* Add transition properties */
  cursor: pointer; /* Add a pointer cursor */
}

/* Define the styles for the "liked" state */
.rooms .btn-copy-passcode.liked {
  transform: scale(1.2); /* Increase the button size when liked */
  opacity: 0.7; /* Reduce opacity when liked */
}

.rooms .btn-secondary,
.rooms .btn-primary {
  background-color: #53c6e0;
  color: #161515;
  border: 2px solid #121212
  
}

.rooms .btn-secondary:hover,
.rooms .btn-primary:hover{
  background-color: #72d5e7;
}


/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .rooms .room-card .card-body > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .rooms .room-card .card-body .passcode {
    flex-direction: column;
    align-items: flex-start;
  }

 
    .rooms .action-buttons {
      flex-direction: row !important; /* Display buttons in a row on smaller screens */
    }
  


}

.rooms .room-summary-item {
  display: flex;
  justify-content: space-between; /* Align label and value to opposite ends */
  margin-bottom: 10px;
  border-bottom: 2px solid #6dbfca; /* Horizontal line separator */
}


/* Styles for the action buttons */
.rooms .action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.rooms .action-buttons button {
  font-size: medium;
}

/* Styles for the pagination */
.rooms .pagination {
  margin-top: 2%;
}

.rooms .pagination li {
  cursor: pointer;
  font-size: medium;
}

.rooms .pagination .page-item.active .page-link {
  background-color:  #53c6e0; 
  border-color:  #53c6e0; 
  color: #fff;
}

/* Styles for the modal */
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 1;
}

/* Add this CSS to style the paragraph in the "No rooms currently" message */
.rooms .noRoom .card-title {
  font-size: large;  
  margin-bottom: 2%; /* Add space below the title */
  font-weight: bold;
}

.rooms .noRoom .card-body p {
  font-size: medium;
  color: #555; /* Change the text color if desired */
  margin-bottom: 2%; /* Add space below the paragraph */
  margin-top: 2%;
  border: 2px solid #161515;
  border-radius: 5px;
  padding: 2% 2%;
}


 .modal-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.rooms .close {
  position: absolute;
  top: 2%;
  right: 2%;
  font-size: large;
  cursor: pointer;
  color: #333;
}

/* Rooms.css */

/* Add these styles to your existing Rooms.css file */

/* Quick Summary Section */
.rooms .quick-summary {
  margin-bottom: 4%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

/* Summary Section */
.rooms .summary {
  background-color: #f8f9fa;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(83, 82, 82, 0.1);
  text-align: center;
  overflow-y: auto; /* Add vertical scroll if content overflows */
}

.rooms .summary h2 {
  font-size: large;
  margin-bottom: 2%;
}

.rooms .summary .summary-item {
  margin: 10px 0;
  flex: calc(50% - 20px);
}

.rooms .summary .summary-item p {
  margin: 5px 0;
}

.rooms .summary .d-flex {
  flex-wrap: wrap; /* Wrap the summary items for smaller screens */
  justify-content: center; /* Center align items on smaller screens */
}

/* Style each summary item's icon separately */
.rooms .chat-icon {
  color:  #53c6e0; 
}

.rooms .webinar-icon {
  color: #28a745;
}

.rooms .conference-icon {
  color: #ffc107;
}

.rooms .broadcast-icon {
  color: #dc3545;
}

/* Style the create room options */


.rooms .option-card {
  flex: calc(22% - 10px); /* Equal width for each option */
  background-color: #97daf0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(48, 47, 47, 0.1);
  text-align: center;
  margin: 1%;
}

 .room-card .endedEvent {
  background-color: #e6c4a2 !important;
}

.room-card .activeEvent {
  background-color: #a2e6db !important;
}

.room-card .scheduledEvent {
  background-color: #a2cbe6 !important;
}

 .room-card .cancelledEvent {
  background-color: #e6a2a2 !important;
  
}


.rooms .option-title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
}

.rooms .create-room-options .room-options{
   margin: 0.1% 0.5%;
   width: 100%;
}

.rooms .create-room-options {
  width: 100%;
  margin: 0.5% 0.5%;
  padding: 0.5% 0.5%;
}

@media (max-width: 768px) {

  .rooms .create-room-options .room-options {
    align-items: center; /* Center-align the options */
    flex: calc(100% - 10px);
    width: 100%;

  }

  .rooms .create-room-options {
    width: 100%;
  }

    .rooms .option-card {
      flex: calc(45% - 5px); /* Two cards per row, considering margin */
      
    }

  .rooms .summary h2 {
    font-size: large;
    margin-bottom: 2%;
  }

  .rooms .summary .summary-item {
    margin: 8px 0;
  }

  .rooms .summary-icon {
    font-size: large;
    margin-bottom: 2%;
  }

  /* Adjust padding and margin for option cards */
  .rooms .option-card {
    padding: 2%;
    margin: 2%;
  }

  /* Reduce font size for option titles */
  .rooms .option-title {
    font-size: large;
  }

  
}

/* Responsive Styles for Mobile */

/* Usage Logs Modal Styles */
 .modal-content-logs {
  width: 95%;
  max-height: 85%;
  margin: 0 auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-x: scroll; /* Enable horizontal scrolling if needed */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.modal-content-actions {
  max-width: 80%;
  max-height: 60%;
  margin: 0 auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  

}

.modal-overlay-logs {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the overlay covers the entire screen */
  font-family: 'Courier New', Courier, monospace;
}

.actionCard h3 {
  font-size: large;
  margin-bottom: 2%;
  text-align: center;
  font-weight: bold;
}
.actionCard{
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2, #ffebcd);
}

.actionCard .card-footer .btn-secondary {
  margin-right: 2%;
  background-color: #53c6e0;  
  color: #171818;
}
.actionCard .card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.actionCard .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2, #ffebcd);
  
}
 

.modal-overlay-actions {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the overlay covers the entire screen */
  
}

.logsChart .table-container {
  overflow-x: auto; /* Enable horizontal scrolling within the table container */
  max-width: 100%;
  font-family: 'Courier New', Courier, monospace;
}

 .logsChart .table {
  width: auto; /* Auto width for table */
  min-width: 100%; /* Ensure the table takes up the full width */
  border-collapse: collapse;
  margin-top: 2%;
  border: 2px solid #070606;
  font-family: 'Courier New', Courier, monospace;
}

 .logsChart h2{
  font-size: large;
  margin-bottom: 2%;
  text-align: center;
}

/* .rooms .logsChart .table th,
.rooms .logsChart .table td {
  padding: 2%;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd; 
  background-color: #f2f2f2;
  text-transform: capitalize;
  font-weight: bold;
  white-space: nowrap; 
  font-family: 'Courier New', Courier, monospace;
  font-size: medium;
} */

.logsChart .table th {
  background-color: #2d2f31;
  color: #fff;
  font-weight: bold;
  font-size: medium;
}

.logsChart .table th:last-child,
.logsChart .table td:last-child {
  border-right: none; /* Remove right border for the last column */
}

.logsChart .table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.logsChart .table tbody tr:hover {
  background-color: #e0e0e0;
}

 .logsChart .btn-secondary {
  background-color: #53c6e0;
  color: #333;
  border: none;
  margin-left: 10px;
  padding: 0px 10px; 
  margin-top: 0px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
}

.rooms .error-message,
.roomsNon .error-message {
 background-color: red;
 color: white;
 font-size: medium;
 margin-top: 10px;
 text-transform: initial;
 font-family: 'Courier New', Courier, monospace;
}

.rooms .success-message,
.roomsNon .success-message {
 background-color: #0eed42;
 color: #0d0c0c;
 margin-top: 2%;
 border-radius: 2%; /* Add rounded corners to error messages */
 margin-bottom: 2%;
 margin-bottom: 2%;
 text-transform: initial;
  font-family: 'Courier New', Courier, monospace;
 }

 /* .logsChart .btn-secondary:hover {
  background-color: #999;
  color: #fff;
} */
