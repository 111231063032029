/* Add a custom class for right alignment */
/* Import Nunito font via Google Fonts CDN */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");

/* Set the font family for the entire page */
body {
  font-family: "Nunito", sans-serif;
}

/* Style the navbar */
.navbar {
  background-color: #fff; /* White background color */
  padding: 0.3rem 0.5rem; /* Top and bottom padding */
  border-bottom: 1px solid #dee2e6; /* Gray border on bottom */
}

/* Style the navbar brand */
.mainNavigation .navbar-brand {
  font-family: "Nunito", sans-serif; /* Apply Nunito font */
  font-weight: 600; /* Bold */
  text-transform: uppercase; /* Uppercase text */
  color: #007bff; /* Blue color */
}

.mainNavigation .navbar-nav a:hover {
  background-color: #ebf3f5;
  color: #161515;
  border: 1px solid #0c0c0c;
  padding: 0.25rem 0.25rem;
}

/* Style the active navbar link with a button appearance */
.mainNavigation .navbar-nav a.active {
  background-color: #53c6e0;
  color: #161515;
  border: 2px solid #0c0c0c;
  padding: 0.25rem 0.25rem;
  border-radius: 12px;
}

.mainNavigation .navbar-nav a.active:hover,
.mainNavigation .navbar-nav div.active:hover {
  background-color: #b2d7df;
  color: #161515;
  border: 2px solid #1a1919;
}

.mainNavigation .navbar-nav-right {
  margin-left: auto;
}

/* Style the navbar links */
.mainNavigation .navbar-nav a {
  font-family: "Nunito", sans-serif; /* Apply Nunito font */
  font-weight: bold; /* Bold */
  text-transform: uppercase; /* Uppercase text */
  color: #000; /* Blue color */
  margin-left: 0.4rem; /* Left margin */
  margin-top: 0%;
}

.mainNavigation .navbar-nav .new-navs {
  font-family: "Nunito", sans-serif; /* Apply Nunito font */
  font-weight: bold; /* Bold */
  text-transform: uppercase; /* Uppercase text */
  color: #000; /* Blue color */
  margin-left: 0.4rem; /* Left margin */
  margin-top: 0%;
}

.mainNavigation .sub-links-card {
  position: absolute; /* Position the sub-links card absolutely */
  left: "auto"; /* Center horizontally */
  /* transform: translateX(-50%); Center horizontally */
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2, #ffebcd);
  border: 2px solid #ddd; /* Double border around the sub-links card */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Enhanced drop shadow */
  padding: 20px; /* Padding inside the sub-links card */
  width: auto; /* Full width of the sub-links card */
  max-width: 550px; /* Maximum width of the sub-links card */
  z-index: 1000; /* Ensure the sub-links card appears above other content */
  color: #151515; /* Text color of the sub-links */
}

.mainNavigation .sub-links-list {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Arrange items in a column */
  align-items: center; /* Center items horizontally */
  list-style: none; /* Remove bullet points from the list */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.mainNavigation .sub-links-list a {
  color: #1d1d1d; /* Link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s; /* Smooth transition for color change */
  padding: 10px 0; /* Adjust padding as needed */
}

.mainNavigation .sub-links-list a:hover {
  color: #1d1d1d; /* Change color on hover */
  text-decoration: none; /* Remove underline on hover */
  background-color: #83bac7;
  border-radius: 12px;
}

@media (max-width: 768px) {
  .mainNavigation .sub-links-card {
    width: 100%; /* Full width of the sub-links card */
    max-width: 70%; /* Maximum width of the sub-links card */
    padding: 10px; /* Padding inside the sub-links card */
    margin-left: 15%;
  }
}
/* -------------------------------------------------- */
/* Events-Specific or Additional Styles               */
/* -------------------------------------------------- */

.mainNavigation .events-create,
.mainNavigation .events-join {
  background-color: transparent;
  color: #090808;
  margin: 0px;
  padding: 5px 10px;
  border-radius: 12px;
  text-decoration: none;
  font-weight: bolder;
}

/* For the "events-create" and "events-join" links */
.mainNavigation .events-create:hover,
.mainNavigation .events-join:hover {
  background-color: #83bac7;
  border-radius: 8px;
}

/* The message box that appears below Create/Join options */
.mainNavigation .events-message {
  margin-top: 10px;
  border-radius: 8px;
  padding: 10px;
  animation: fadeIn 0.4s forwards;
}

/* Generic fade in */
.mainNavigation .fade-in {
  opacity: 0;
  animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Different background colors depending on message type */
.mainNavigation .events-message.not-logged-in {
  background-color: #fff3cd; /* pale yellow info style */
  border: 1px solid #ffeeba;
  color: #856404;
}
.mainNavigation .events-message.error {
  background-color: #f8d7da; /* pale red error style */
  border: 1px solid #f5c6cb;
  color: #721c24;
}

/* Container for extra actions (log in / proceed with demo) */
.mainNavigation .events-message-actions {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.mainNavigation .events-message-actions .btn {
  font-size: 0.85rem;
  padding: 5px 10px;
}
